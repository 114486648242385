import React from 'react';

export default function Info(): JSX.Element {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22">
      <g
        id="Careers-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Job-Detail" transform="translate(-153.000000, -394.000000)">
          <g id="Blue_Info" transform="translate(152.500000, 393.000000)">
            <circle id="Oval" fill="#1475D4" cx="11.73" cy="11.73" r="10.58" />
            <path
              d="M12.59802,13.478 L12.91082,6.44 L10.5179,6.44 L10.84634,13.478 L12.59802,13.478 Z M11.72218,17.02828 C12.41034,17.02828 12.98902,16.4496 12.98902,15.77708 C12.98902,15.08892 12.41034,14.51024 11.72218,14.51024 C11.03402,14.51024 10.47098,15.08892 10.47098,15.77708 C10.47098,16.4496 11.03402,17.02828 11.72218,17.02828 Z"
              id="!"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
